import React, { useReducer } from 'react'
import FilterContext from './FilterContext'
import { isOnCoursesPage, isServerRendering } from '../../utils/applicationHelper'
import useLocation from '../../hooks/useLocation'

function reducer(filterState, { type, data }) {
	const url = new URL(useLocation().href)
	let filterCount = 0
	switch (type) {
		// Clear all filters and update the url parameters accordingly
		case 'CLEAR_ALL':
			const {
				subCategories,
				subCategoriesCount,
				locations,
				locationsCount,
				globalTags,
				skillTags,
				globalTagsCount,
				skillTagsCount,
			} = filterState
			if (subCategoriesCount > 0) {
				for (let subCategory of subCategories) {
					subCategory.checked = false
				}
			}
			if (locationsCount > 0) {
				for (let location of locations) {
					location.checked = false
				}
			}
			if (globalTagsCount > 0) {
				for (let tag of globalTags) {
					tag.checked = false
				}
			}
			if (skillTagsCount > 0) {
				for (let tag of skillTags) {
					tag.checked = false
				}
			}
			url.searchParams.delete('l[]')
			url.searchParams.delete('gt[]')
			url.searchParams.delete('st[]')
			window.history.replaceState({}, document.title, url.pathname + url.search)
			return {
				...filterState,
				...data,
				subCategories: subCategories,
				locations: locations,
				globalTags: globalTags,
				skillTags: skillTags,
				subCategoriesCount: 0,
				locationsCount: 0,
				globalTagsCount: 0,
				skillTagsCount: 0,
			}
		// Filter on the newly selected locations and update the url parameters
		case 'LOCATION':
			url.searchParams.delete('l[]')
			filterCount = data.reduce((result, { checked, id }) => {
				if (checked) {
					result++
					url.searchParams.append('l[]', id)
				}
				return result
			}, 0)
			window.history.replaceState({}, document.title, url.pathname + url.search)
			return {
				...filterState,
				locations: data,
				locationsCount: filterCount,
			}
		// Filter on the newly selected global tags and update the url parameters
		case 'GLOBAL_TAG':
			url.searchParams.delete('gt[]')
			filterCount = data.reduce((result, { checked, id }) => {
				if (checked) {
					result++
					url.searchParams.append('gt[]', id)
				}
				return result
			}, 0)
			window.history.replaceState({}, document.title, url.pathname + url.search)
			return {
				...filterState,
				globalTags: data,
				globalTagsCount: filterCount,
			}
		// Filter on the newly selected skill tags and update the url parameters
		case 'SKILL_TAG':
			url.searchParams.delete('st[]')
			filterCount = data.reduce((result, { checked, id }) => {
				if (checked) {
					result++
					url.searchParams.append('st[]', id)
				}
				return result
			}, 0)
			window.history.replaceState({}, document.title, url.pathname + url.search)
			return {
				...filterState,
				skillTags: data,
				skillTagsCount: filterCount,
			}
		// Filter on the newly entered search query and update the url parameters.
		// If the query matches a filter option (e.g. "Stockholm") that filter option is selected instead and the query is reset
		case 'SEARCH_QUERY':
			if (data) {
				const locationIdFoundFromQuery = lookForMatchingFilterInQuery(filterState.locations, data)
				const globalTagIdFoundFromQuery = lookForMatchingFilterInQuery(filterState.globalTags, data)
				// const timeIdFoundFromQuery = lookForMatchingFilterInQuery(filterState.times, data)
				if (locationIdFoundFromQuery !== undefined) {
					url.searchParams.delete('l[]')
					url.searchParams.append('l[]', locationIdFoundFromQuery.id)
					url.searchParams.delete('q')
					data = ''
					filterState.locationsCount = 1
				} else if (globalTagIdFoundFromQuery !== undefined) {
					url.searchParams.delete('gt[]')
					url.searchParams.append('gt[]', globalTagIdFoundFromQuery.id)
					url.searchParams.delete('q')
					data = ''
					filterState.globalTagsCount = 1
				} else {
					url.searchParams.set('q', data)
				}
			} else {
				url.searchParams.delete('q')
			}
			window.history.replaceState({}, document.title, url.pathname + url.search)
			return {
				...filterState,
				searchQuery: data,
			}
		// Updates the course count for each filter option
		case 'FILTER_COURSE_COUNT':
			if (data.newFilterState.initialFilterState) {
				// A new filter state was calculated on the server, i.e. it was not a pagination
				filterState = { ...filterState, ...data.newFilterState.initialFilterState }
				// We only fetch an array of sub categories from the server, so group them on category ID
				filterState.categories = {}
				filterState = mapCourseCounts(filterState, data.newFilterState)
			}
			if (!isServerRendering() && data.cacheKey) {
				const cache = window.filterCache
				if (!cache[data.cacheKey]) cache[data.cacheKey] = {}
				// Cache the filter state
				cache[data.cacheKey].filterProviderState = deepCopyFilterState(filterState)
				delete cache[data.cacheKey].filterProviderState.openFilterOption
			}
			return filterState
		// There was a hit in the cache, so use the cached state
		case 'SET_STATE_FROM_CACHE':
			return { ...filterState, ...deepCopyFilterState(data.cachedState) }
		// Update the list type
		case 'LIST_TYPE':
			if (data === 'COURSE_DATE') {
				url.searchParams.set('type', 'date')
			} else if (data === 'PRICE_HIGH') {
				url.searchParams.set('type', 'price_high')
			} else if (data === 'PRICE_LOW') {
				url.searchParams.set('type', 'price_low')
			} else {
				url.searchParams.delete('type')
			}
			window.history.replaceState({}, document.title, url.pathname + url.search)
			return {
				...filterState,
				listType: data,
			}
		// Set the openFilterOption, which can either be a filter option (e.g. SUB_CATEGORY, LOCATION), undefined (modal is closed) or NONE (no options are opened but modal is still open)
		case 'OPEN_FILTER_OPTION':
			return {
				...filterState,
				openFilterOption: data,
			}
		default:
			throw new Error()
	}
}
// Make a deep copy of the filter state so the current state doesn't update a cached state since they will be referencing the same objects otherwise
const deepCopyFilterState = filterState => {
	return JSON.parse(JSON.stringify(filterState)) // Only works as a technique for deep copy if the objects doesn't contain any functions
}

// See if the search query matches a filter option
export function lookForMatchingFilterInQuery(filterOptions, query) {
	let filterIdFoundInQuery
	let filterOptionFoundInQuery
	for (let filterOption of filterOptions) {
		if (filterOption.name.toLowerCase().trim() === query.toLowerCase().trim()) {
			filterIdFoundInQuery = filterOption.id
			filterOptionFoundInQuery = filterOption
			break
		}
	}
	if (filterIdFoundInQuery !== undefined) {
		for (let filterOption of filterOptions) filterOption.checked = filterOption.id === filterIdFoundInQuery
	}
	return filterOptionFoundInQuery
}

// TODO: Move all of this to backend so when the client receives this it should just set its state to the server response object
function mapCourseCounts(filterState, newFilterState) {
	if (!filterState) return newFilterState
	let numberOfFilters = 0
	for (let subCategoryIndex = 0; subCategoryIndex < filterState.subCategories.length; subCategoryIndex++) {
		const subCategory = filterState.subCategories[subCategoryIndex]
		const subCategoryCoursesCount = newFilterState.subCategoryCourseCount[subCategoryIndex].coursesCount
		filterState.subCategories[subCategoryIndex].listedCoursesCount = subCategoryCoursesCount
		// We only fetch an array of sub categories from the server, so generate their data from their sub categories
		if (!filterState.categories[subCategory.categoryId])
			filterState.categories[subCategory.categoryId] = {
				name: subCategory.categoryTitle,
				listedCoursesCount: 0,
				path: subCategory.categoryPath,
				subCategories: [],
			}
		filterState.categories[subCategory.categoryId].listedCoursesCount += subCategoryCoursesCount
		filterState.categories[subCategory.categoryId].subCategories.push(subCategory)
		if (filterState.subCategories[subCategoryIndex].checked) numberOfFilters++
	}
	for (let locationIndex = 0; locationIndex < filterState.locations.length; locationIndex++) {
		const coursesCount = newFilterState.locationCourseCount[locationIndex].coursesCount
		filterState.locations[locationIndex].listedCoursesCount = coursesCount
		if (filterState.locations[locationIndex].checked) numberOfFilters++
	}
	for (let tagIndex = 0; tagIndex < filterState.globalTags.length; tagIndex++) {
		const coursesCount = newFilterState.globalTagCourseCount[tagIndex].coursesCount
		filterState.globalTags[tagIndex].listedCoursesCount = coursesCount
		if (filterState.globalTags[tagIndex].checked) numberOfFilters++
	}
	for (let tagIndex = 0; tagIndex < filterState.skillTags.length; tagIndex++) {
		const coursesCount = newFilterState.skillTagCourseCount[tagIndex].coursesCount
		filterState.skillTags[tagIndex].listedCoursesCount = coursesCount
		if (filterState.skillTags[tagIndex].checked) numberOfFilters++
	}
	filterState.numberOfFilters = numberOfFilters
	filterState.totalCount = newFilterState.totalCourseCount
	filterState.listType = newFilterState.listType
	return filterState
}
export default ({ children, ssrFilterState }) => {
	let initialFilterState
	if ((isServerRendering() || !window.ssrFilterStateHasBeenUsed) && ssrFilterState) {
		// Initialize the filter state from SSR
		ssrFilterState.initialFilterState.categories = {}
		initialFilterState = mapCourseCounts(ssrFilterState.initialFilterState, ssrFilterState)
	} else {
		// Fetch the filter state from the server
		initialFilterState = {
			listType: 'COURSE',
			locations: [],
			globalTags: [],
			skillTags: [],
			subCategories: [],
			categories: {},
			locationsCount: 0,
			globalTagsCount: 0,
			skillTagsCount: 0,
			subCategoriesCount: 0,
		}
	}
	let [filterState, dispatch] = useReducer(reducer, initialFilterState)

	if (isOnCoursesPage()) {
		// The SearchBar.js is outside of the FilterProvider, so when on MarketPage.js it can not update the filter state through the provider by subscribing and using the dispatch, since it is not a child component.
		// Therefore we need to add it to the window so SearchBar.js can update MarketPage's filter state with new search queries.
		window.marketplacePageDispatch = dispatch
	}
	if (!isServerRendering()) window.marketplacePageFilterState = filterState // This is needed on every page with a search bar
	return (
		<FilterContext.Provider
			value={{
				filterState,
				dispatch,
			}}
		>
			{children}
		</FilterContext.Provider>
	)
}
