import { isServerRendering } from '../utils/applicationHelper'

let csrfToken = ''

export const setCsrfToken = () => {
	csrfToken = isServerRendering() ? '' : document.getElementsByName('csrf-token')[0].content
}

const getCsrfToken = () => csrfToken

export default getCsrfToken
