import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import useRoutes from '../../../hooks/useRoutes'
import { useTranslation } from 'react-i18next-new'

import { SmallPageSection } from '../layout/PageComponents'
import Button from './Button'
import { isLocalStorageAvailable } from '../../../utils/applicationHelper'
import styled from 'styled-components'
import UserContext from '../../../contexts/user/UserContext'

const ChildWrapper = styled.div`
	cursor: pointer;
	> * {
		pointer-events: none;
	}
`

const UpgradeTierLink = ({ children }) => {
	const history = useHistory()
	const routes = useRoutes()
	const { t } = useTranslation()
	const { userState, dispatch } = useContext(UserContext)
	const { user } = userState
	const onClick = () => {
		if (isLocalStorageAvailable()) {
			// Make sure the user gets routed back to the feature they were prompted to upgrade tier in order to use, after they upgrade tier
			localStorage.setItem('upgradeTier', 'standard')
			localStorage.setItem('upgradeTierRouteBack', location.pathname)
		}
		history.push(routes.admin.host.overview.path + '?tab=1')
	}

	return children ? (
		user.needsStandard ? (
			<ChildWrapper onClick={onClick}>{children}</ChildWrapper>
		) : (
			children
		)
	) : (
		<SmallPageSection>
			<Button onClick={onClick}>{t('common:buttons.upgradeTier')}</Button>
		</SmallPageSection>
	)
}

export default UpgradeTierLink
