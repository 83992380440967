import styled from 'styled-components'
import React, { useCallback, useState } from 'react'
import ScreenListener from './ScreenListener'
import { getCloudinaryImageUrl } from '../../../utils/imageHelper'
import PropTypes from 'prop-types'

const StyledImage = styled.img`
	position: absolute;
	width: 100%;
	border-radius: 2px;
	${({ blackAndWhite }) => (blackAndWhite ? 'filter: grayscale(100%);' : '')}
`
const ImageWrapper = styled.div`
	overflow: hidden;
	position: relative;
	height: 0;
	width: 100%;
	padding-bottom: ${({ paddingBottom }) => paddingBottom}%;
	${({ shadow }) => (shadow ? 'box-shadow: #00000017 1px 1px 9px 0px;' : '')}
`

const Image = ({ imagePath, parentRef, type, alt, addedWidth = 100, blackAndWhite, sourceWidth }) => {
	if (!imagePath) return null
	// A constant width for the source image. Used for server side rendering images
	if (!sourceWidth) {
		// If the image is hidden at the time getBoundingClientRect().width is called, it will return 0.
		// Default it therefore to e.g 300
		const containerWidth = parentRef.getBoundingClientRect().width || 300
		// Round up image width to nearest 100 (e.g 400, 500) to reduce the number of transformations on Cloudinary
		sourceWidth = Math.ceil((containerWidth + addedWidth) / 100) * 100
	}
	return (
		<StyledImage
			width={'100%'}
			height={'100%'}
			src={getCloudinaryImageUrl(imagePath, sourceWidth, type)}
			alt={alt}
			blackAndWhite={blackAndWhite}
		/>
	)
}

function ResponsiveImage(props) {
	const { paddingBottom, type, sourceWidth, children, origninalRatioPadding, shadow } = props
	const [parentRef, setParentRef] = useState(null)
	const measuredRef = useCallback(node => setParentRef(node), [])
	return (
		<ImageWrapper
			shadow={shadow}
			paddingBottom={paddingBottom || getPaddingBottom(type, origninalRatioPadding)}
			ref={measuredRef}
		>
			{sourceWidth ? (
				<Image parentRef={parentRef} {...props} />
			) : (
				<ScreenListener>{parentRef && <Image parentRef={parentRef} {...props} />}</ScreenListener>
			)}
			{children}
		</ImageWrapper>
	)
}

// Set the height of the image depending on the image type, so the parent div takes up the intended height even though the image hasn't loaded yet
const getPaddingBottom = (type, origninalRatioPadding) => {
	if (type === 'RECTANGLE') {
		return 38.5
	} else if (type === 'BIG_RECTANGLE') {
		return 62.5
	} else if (type === 'THIN_RECTANGLE') {
		return 33.3
	} else if (type === 'VERTICAL_RECTANGLE') {
		return 114
	} else if (type === 'BIG_VERTICAL_RECTANGLE') {
		return 129
	} else if (type === 'SQUARE') {
		return 100
	} else if (type === 'PROFILE') {
		return 100
	} else if (type === 'ROUND') {
		return 100
	} else if (type === 'ORIGINAL_RATIO') {
		return origninalRatioPadding
	} else {
		return 0
	}
}

ResponsiveImage.propTypes = {
	imagePath: PropTypes.string,
	sourceWidth: PropTypes.number,
	origninalRatioPadding: PropTypes.number, // If type ORIGINAL_RATIO is used, then use this to set the padding (the image's height as a percentage of its width) of the image
	alt: PropTypes.string,
	/*type: PropTypes.string.oneOf([
		'RECTANGLE',
		'BIG_RECTANGLE',
		'VERTICAL_RECTANGLE',
		'BIG_VERTICAL_RECTANGLE',
		'SQUARE',
		'PROFILE',
		'ROUND',
		'ORIGINAL_RATIO',
	]),*/
	paddingBottom: PropTypes.number, // The bottom padding is decided automatically with getPaddingBottom(), but can be overridden.
	blackAndWhite: PropTypes.bool, // Set the image to black and white.
}

export default ResponsiveImage
