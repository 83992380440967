import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next-new'
import { theme } from '../../../utils/theme'
import { Breadcrumbs, UnderlinedSpan } from '../../reusable_components/Typography'
import { openUserMenu } from '../../layout/header/UserMenu'
import CrocodileRight from '../../reusable_components/svg_icons/CrocodileRight'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import Filter from '../../reusable_components/svg_icons/Filter'
import {
	CenteredSection,
	GrayBox,
	LeftAlignedFlexBox,
	SmallPageSection,
	SmallPageSectionRepulsiveRow,
} from '../../reusable_components/layout/PageComponents'
import PremiumIndicator from '../../reusable_components/other/PremiumIndicator'
import UpgradeTierLink from '../../reusable_components/buttons/UpgradeTierLink'

export const AdminPageWrapper = styled.div`
	width: 100%;
	padding-top: ${theme.paddings.small};
	padding-bottom: ${theme.paddings.XLarge};
	${theme.breakpoints.tablet.overAndExcluding} {
		max-width: 700px;
	}
`

export const AdminBreadcrumbs = ({ children }) => {
	const { t } = useTranslation()
	return (
		<Breadcrumbs style={{ marginBottom: theme.margins.small }}>
			<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
				<UnderlinedSpan onClick={openUserMenu}>{t('shared:userMenu.myAccount')}</UnderlinedSpan>
			</Breakpoint>
			<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
				<span>{t('shared:userMenu.myAccount')}</span>
			</Breakpoint>
			<CrocodileRight height="16px" width="16px" />
			<span>{children}</span>
		</Breadcrumbs>
	)
}

export const AdminFilterTitle = props => {
	const { t } = useTranslation()
	return (
		<LeftAlignedFlexBox innerMargin={theme.margins.XXSmall}>
			<p>{t('shared:filterContainer.filter')}</p>
			<Filter />
		</LeftAlignedFlexBox>
	)
}

export const UpgradeToStandardGrayBox = props => (
	<GrayBox
		style={{
			padding: theme.paddings.small,
			marginBottom: theme.margins.large,
			marginTop: theme.margins.large,
			maxWidth: '500px',
			...props.style,
		}}
	>
		<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
			<CenteredSection>
				<PremiumIndicator />
				<SmallPageSection>
					<p>{props.text}</p>
				</SmallPageSection>
			</CenteredSection>
		</Breakpoint>
		<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
			<SmallPageSectionRepulsiveRow>
				<p>{props.text}</p>
				<PremiumIndicator />
			</SmallPageSectionRepulsiveRow>
		</Breakpoint>
		<SmallPageSection>
			<CenteredSection onlyCenterOnMobile>
				<UpgradeTierLink />
			</CenteredSection>
		</SmallPageSection>
	</GrayBox>
)

export const NeedsToUpgradeTierWrapper = styled.div`
	${({ needsToUpgradeTier }) =>
		needsToUpgradeTier
			? css`
					color: ${theme.colors.lightTextColor};
					p,
					a {
						color: ${theme.colors.lightTextColor} !important;
					}
					iframe {
						pointer-events: none !important;
					}
				`
			: ''}
`
