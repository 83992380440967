import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../utils/theme'
import Diamond from '../svg_icons/Diamond'

const Badge = styled.div`
	background: ${theme.colors.fade(theme.colors.gold, 0.8)};
	color: white;
	border-radius: 5px;
	padding: ${theme.paddings.XXSmall} ${theme.paddings.small};
	display: flex;
	align-items: center;
`

const PremiumIndicator = props => {
	return (
		<Badge>
			Premium&nbsp;&nbsp;
			<Diamond width={16} height={16} />
		</Badge>
	)
}

export default PremiumIndicator
